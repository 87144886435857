import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-4dcfc5d1")
const _hoisted_1 = { class: "letter" }
const _hoisted_2 = { class: "letter" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_Center = _resolveComponent("Center")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_Center, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_BrainSpinner)
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_DataTable, {
        key: 1,
        value: _ctx.videos,
        responsiveLayout: "scroll",
        class: "content-table gray-table borders-white"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, { header: "Título" }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("span", _hoisted_1, [
                _createVNode(_component_router_link, {
                  to: {
              name: 'VideoDetail',
              params: {
                videoId: data._id
              }
            }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(data.title), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "publishDate",
            header: "Fecha de publicación"
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(data.publishDate, _ctx.FormatDates.LatinAmerica)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "categories",
            header: "Categoría"
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(data.categories[0]), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "likes",
            header: "Likes"
          }),
          _createVNode(_component_Column, {
            field: "views",
            header: "Reproducciones"
          }),
          _createVNode(_component_Column, {
            field: "published",
            header: "Estado"
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("span", null, _toDisplayString(data.published ? 'Publicado' : 'No publicado'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, { header: "" }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("span", null, [
                _createVNode(_component_Button, {
                  onClick: ($event: any) => (_ctx.toggleMenu($event, data)),
                  class: "p-button-text overlay-menu-trigger",
                  icon: "pi pi-ellipsis-v"
                }, null, 8, ["onClick"]),
                _createVNode(_component_Menu, {
                  id: "overlay_menu",
                  ref: "menuRef",
                  model: _ctx.items,
                  popup: true
                }, null, 8, ["model"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"]))
}