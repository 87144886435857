
import { Video } from '@/models/coderplus/classes/Video'
import { ComponentPublicInstance, defineComponent, onMounted, ref } from 'vue'
import { CoderplusAPI } from '@/api/coderplus.api'
import BrainSpinner from '@/components/commons/BrainSpinner.vue'
import { formatDate } from '@/utils/formatDate'
import { FormatDates } from '@/models/enums'
import { MenuItem } from 'primevue/menuitem'
import Menu from 'primevue/menu'
import { useToast } from 'primevue/usetoast'
import {
  ToastSummaries,
  ToastSeverities,
  ToastLife
} from '@/models/components/toast/enums'

export default defineComponent({
  components: {
    BrainSpinner,
    Menu
  },
  props: {},
  emits: ['onDeleteVideo'],
  setup (props, ctx) {
    const toast = useToast()
    const videos = ref<Video[]>()
    const menuRef = ref()
    const loading = ref<boolean>(false)
    const menu = ref<ComponentPublicInstance<Menu> | null>()
    const items = ref<MenuItem[]>([])
    const toggleMenu = (event: MouseEvent, data: Video) => {
      items.value = [
        {
          label: 'Opciones',
          items: [
            {
              label: 'Eliminar',
              icon: 'pi pi-trash',
              command: async () => {
                try {
                  await CoderplusAPI.deleteVideoById(data._id!)
                  videos.value = videos.value?.filter(
                    video => video._id !== data._id
                  )
                  console.log(videos)
                  toast.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Video eliminado',
                    life: 3000
                  })
                } catch (error) {
                  toast.add({
                    summary: ToastSummaries.Error,
                    detail: 'Ocurrió un error al intentar eliminar video',
                    severity: ToastSeverities.Error,
                    life: ToastLife.Default
                  })
                }
              }
            }
          ]
        }
      ] as MenuItem[]
      menuRef.value?.toggle(event)
      /* menu.value && menu.value.toggle(event) */
    }
    onMounted(async () => {
      try {
        loading.value = true
        videos.value = await CoderplusAPI.findAllVideos()
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    })
    return {
      menuRef,
      items,
      formatDate,
      menu,
      toggleMenu,
      FormatDates,
      loading,
      videos
    }
  }
})
