
import { defineComponent } from 'vue'
import { CoderPlusNames } from '@/router/route-names'
import ModuleHeader from '@/components/commons/boxes/ModuleHeader.vue'
import VideosTable from '../components/VideosTable.vue'

export default defineComponent({
  components: {
    VideosTable,
    ModuleHeader
  },
  props: {},
  setup () {
    return {
      CoderPlusNames
    }
  }
})
